import React from 'react'
import PageComponent from '../components/Page'
import Layout from '../components/Layout'
import useStoryblok from '../utils/storyblok'

export default function Page({ pageContext, location }) {
  const story = useStoryblok(pageContext.story, location)

  return (
    <Layout location={location}>
      <PageComponent blok={story.content} />
    </Layout>
  )
}
